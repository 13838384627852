function Overview() {
    return (
        <>
        <div className="container mt-5">
          <div className="card mb-3 text-center shadow">
            <div className="row no-gutters">
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                <img src="/main_stage_1.webp" className="card-img" alt="..." />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title fs-1 mb-4">究極の対決でファンと直接繋がる！</h5>
                  <p className="card-text fs-3">配信者が自らの手で視聴者との対決を演出し、共にゲームを盛り上げるための最高のエンターテイメントを提供します。</p>
                  <p className="card-text fs-3">あなたの策略とファンの反応が生み出す、最高の展開がここにあります。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container mt-5">
          <div className="card mb-3 text-center shadow">
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title fs-1 mb-4">ゲームを通じて一体感を楽しむ</h5>
                  <p className="card-text fs-3">このゲームは、配信者と視聴者がリアルタイムで対決することで、従来のゲーミングの枠を超えた新しい体験を提供します。</p>
                  <p className="card-text fs-3">プレイヤーは戦略を練り、リアルタイムでの反応が求められるため、毎回異なる結末が生まれます。共に作り上げるゲームのストーリーは、参加者全員にとって忘れられない体験となるでしょう。</p>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                <img src="/main_stage_2.webp" className="card-img" alt="..." />
              </div>
            </div>
          </div>
        </div>
        </>
      );
  }
  
  export default Overview;