import React from "react";

const TermsOfService = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-8 col-md-10 mx-auto">
          <h2 id="アマエビゲームズ利用規約" className="text-center mb-4">
            <strong>アマエビゲームズ利用規約</strong>
          </h2>
          <p>アマエビゲームズ利用規約（以下「本規約」といいます）は、ライブ配信を行う配信者とその視聴者が、一緒に遊ぶゲームを提供するWebサービス「アマエビゲームズ」（以下「本サービス」といいます）を企画、開発、運営を行う者（以下「運営者」といいます）が、本サービスに関する利用条件及び運営者とユーザーとの間の権利義務関係を定めるものです。</p>
          <p>なお、本規約の各条項は本サービスの機能が全て実装された状態を想定して規定されているため、本サービスの機能を段階的に実装している時期においては、未実装の機能に関する本規約の条項は、当該機能の実装をもって効力が生じるものとします（この文は、全ての機能が実装された時点で削除予定です）。</p>
          <p> </p>
          <h3 id="第１条本規約">第１条（本規約）</h3>
          <p>1 本規約における各条項の他、運営者が本システムでユーザーに提示する操作方法、注意事項、よくある質問等は、本規約の一部を構成するものとします。</p>
          <p>2 本規約で使用する主な用語の定義は、以下のとおりとします。</p>
          <p>① 「本システム」：運営者が本サービスを提供するために運営するWebシステムをいいます。</p>
          <p>② 「ユーザー」：本サービスを利用する全ての個人をいい、配信ユーザーと視聴ユーザーの両方を含みます。</p>
          <p>③ 「配信ユーザー」：ライブ配信を行う側の立場のユーザーをいいます。</p>
          <p>④ 「視聴ユーザー」：ライブ配信を視聴する側の立場のユーザーをいいます。</p>
          <p>⑤ 「ライブ配信サービス」：本システムとの連携が可能なライブ配信サービスをいいます。ライブ配信サービスを行うことができるプラットフォームを「配信プラットフォーム」といいます。</p>
          <p>⑥ 「視聴者参加型ゲーム」：ライブ配信サービスの画面を通じて、配信ユーザーと複数の視聴ユーザーが対決するタイプのオンラインゲームをいいます。</p>
          <p>⑦ 「配信者対決型ゲーム」：ライブ配信サービスの画面を通じて、配信ユーザー同士が対決するタイプのオンラインゲームをいいます。</p>
          <p>⑧ 「月額利用料」：配信者対決型ゲームを行うために、配信ユーザーが運営者に対して毎月支払う利用料をいいます。</p>
          <p>⑨ 「配信ユーザー支援」：特定の配信ユーザーを支援する目的で、視聴ユーザーが当該配信ユーザーのために、自発的に運営者に対して月額利用料の支払いを行うことをいいます。</p>
          <p>⑩ 「有料ユーザー」：自ら又は配信ユーザー支援によって月額利用料の支払いを行うことで、配信者対決型ゲームを行うことができる配信ユーザーをいいます。</p>
          <p>⑪ 「操作機器」：ユーザーが本サービスを利用してオンラインゲームの操作を行うために使用するユーザー自身のスマートフォン機器をいいます。</p>
          <p>⑫ 「指定決済事業者」：運営者が月額利用料の決済、精算、支払等の業務を委託する事業者をいいます。</p>
          <p>⑬ 「プライバシーポリシー」：運営者が定める本サービスにおける個人情報の取扱い方針をいいます。</p>
          <p> </p>
          <h3 id="第２条視聴ユーザーによる利用">第２条（視聴ユーザーによる利用）</h3>
          <p>1 視聴ユーザーは、本規約及びプライバシーポリシーをあらかじめ承認のうえ、本システムのアカウントを取得することなく、第９条に定める手順によって、視聴者参加型ゲームに参加することができるものとします。</p>
          <p>2 視聴ユーザーは、視聴者参加型ゲームへの参加に必要な操作機器又は通信環境の準備等について、自らの責任と費用で行うものとし、運営者は、当該準備等へのサポートについては行わないものとします。</p>
          <p>3 視聴者ユーザーが配信ユーザー支援を行う場合は、第８条に従うものとします。</p>
          <p> </p>
          <h3 id="第３条配信ユーザーによる利用手続き">第３条（配信ユーザーによる利用手続き）</h3>
          <p>
            1 配信ユーザーとして本サービスを利用することを希望するユーザーは、本規約及びプライバシーポリシーをあらかじめ承認のうえ、自らの操作機器により、運営者の指定するプラットフォーム事業者のアカウントの使用によってソーシャルログインを行う方法で、本システムのアカウントを取得することができるものとします。なお、未成年のユーザーは、あらかじめ親権者の同意を得たうえで、本項の手続きを行うものとします。
          </p>
          <p>2 配信ユーザーは、本システム上において、ニックネーム、配信プラットフォームの情報、その他の情報を登録することができるものとします。</p>
          <p>3 配信ユーザーは、本サービスの利用に必要な操作機器又は通信環境の準備等について、自らの責任と費用で行うものとし、運営者は、当該準備等へのサポートについては行わないものとします。</p>
          <p> </p>
          <h3 id="第４条配信ユーザーのログイン情報管理">第４条（配信ユーザーのログイン情報管理）</h3>
          <p>1 配信ユーザーは、本システムへのログインに必要となるプラットフォーム事業者のアカウント情報（以下「ログイン情報」といいます）を厳格に管理するものとし、第三者への開示（SNS等への投稿やライブ配信時のユーザーへの提示を含みます）を行ってはならないものとします。</p>
          <p>2 配信ユーザーは、ログイン情報の第三者（ユーザー、知人、友人、家族を含みます）への貸与、譲渡、名義変更、その他の処分を行ってはならないものとします。</p>
          <p>3 ログイン情報の第三者利用により発生した損害は配信ユーザーが負担するものとし、運営者は、配信ユーザーの損害から一切免責されるものとします。</p>
          <p>4 運営者は、配信ユーザーがログイン情報を紛失した場合においても、いかなる責任も負わないものとします。</p>
          <p> </p>
          <h3 id="第５条配信ユーザー情報等の変更">第５条（配信ユーザー情報等の変更）</h3>
          <p>配信ユーザーは、本システム上に登録した情報に変更がある場合、速やかに本システム所定の方法で、変更手続きを行うものとします。</p>
          <p> </p>
          <h3 id="第６条配信ユーザーとしての利用終了">第６条（配信ユーザーとしての利用終了）</h3>
          <p>1 配信ユーザーは、配信ユーザーとしての本サービスの利用を終了する場合、本システム所定の方法で、本システムのアカウントを削除するものとします。</p>
          <p>2 配信ユーザーが退会した場合において、当該配信ユーザーが自らの月額利用料の支払い又は配信ユーザー支援により対決型ゲームの利用資格を有していた際には、当該利用資格は将来に向かって失効するものとします。</p>
          <p> </p>
          <h3 id="第７条有料ユーザーによる月額利用料の支払い">第７条（有料ユーザーによる月額利用料の支払い）</h3>
          <p>1 配信者対決型ゲームの利用を希望する配信ユーザーは、第３条の利用手続きに加え、指定決済事業者への登録を行うことで、指定決済事業者のアカウントを取得するものとします。</p>
          <p>2 配信者対決型ゲームの利用を希望する配信ユーザーは、有料ユーザーとして、指定決済事業者を通じたクレジットカード決済による方法によって、月額利用料を毎月支払うものとします。</p>
          <p>3 月額利用料については、有料ユーザーが指定決済事業者によって初回の決済を行った日と同じ日（以下「決済日」といいます）において、毎月、翌月分の決済が行われるものとします。</p>
          <p>4 有料ユーザーの解約を希望するユーザーは、指定決済事業者所定の解約手続きを行うものとします。ただし、決済日前日の23時59分までに当該解約手続きを行わなかったときは、翌月分の月額利用料が決済されるものとします。</p>
          <p>5 運営者は、いかなる理由によっても、配信ユーザーに対し、一度収受した月額利用料の返金は行わないものとします。ただし、本システムの障害等により本サービスが一切提供できなくなった場合についてはこの限りではありません。</p>
          <p>6 本条各項については、運営者が本システムに必要な機能を実装した以降から、ユーザーに適用となるものとします。</p>
          <p> </p>
          <h3 id="第８条配信ユーザー支援">第８条（配信ユーザー支援）</h3>
          <p>1 視聴ユーザーは、本システムにメールアドレスを登録し、指定決済事業者における決済に必要な通知を受けることによって、配信ユーザー支援を行うことができるものとします。</p>
          <p>2 配信ユーザー支援を行うことを希望する視聴ユーザーは、指定決済事業者にアクセスのうえ、特定の配信ユーザー（既に有料ユーザーである場合を除きます）のために、月額利用料の決済を行うものとします。</p>
          <p>3 配信ユーザーは、運営者が定めた月数を限度として、配信ユーザー支援を受けることができるものとし、視聴ユーザーは、対象とする配信ユーザーに関して残存する月数の範囲内で、配信ユーザー支援を行うことができるものとします。</p>
          <p>4 配信ユーザー支援を受けた配信ユーザーは、当該支援が有効である間に限り、有料ユーザーとして、対決型ゲームを行うことができるものとします。</p>
          <p>5 配信ユーザー支援は、原則として視聴ユーザーの自発的な意思で行われるべきものであるため、配信ユーザーは視聴ユーザーに対し、配信ユーザー支援を強要する等の言動を行ってはならないものとします。</p>
          <p>6 第７条各項の定めは、配信ユーザー支援を行う視聴ユーザーに対して準用するものとします。</p>
          <p>7 本条各項については、運営者が本システムに必要な機能を実装して以降から、ユーザーに適用となるものとします。</p>
          <p> </p>
          <h3 id="第９条視聴者参加型ゲーム">第９条（視聴者参加型ゲーム）</h3>
          <p>1 配信ユーザーは、自らの操作機器から本システムにログインのうえ、ライブ配信サービスの画面上に、本システムによって発行されたゲームアクセス用のURL又はQRコードを表示することによって、視聴者参加型ゲームの参加者を募集することができるものとします。</p>
          <p>2 視聴ユーザーは、配信ユーザーが表示したURL又はQRコードによって、自らの操作機器から本システムにアクセスすることにより、ライブ配信サービスの画面において、視聴者参加型ゲームに参加することができるものとします。</p>
          <p>3 配信ユーザーは、以下を目的とした視聴者参加型ゲームを行ってはならないものとし、視聴ユーザーは、これに迎合してはならないものとします。</p>
          <p>① 刑法に定める賭博罪に該当する行為につながる目的</p>
          <p>② 政治的な観点での意見集約等を行う目的</p>
          <p>③ 特定の人物に対する非難を行う目的</p>
          <p>④ 自らの意見、見解、立場等を正当化する目的</p>
          <p>⑤ 必要以上に視聴ユーザーを煽る目的</p>
          <p>⑥ その他娯楽の範囲を超えた目的</p>
          <p> </p>
          <h3 id="第10条配信者対決型ゲーム">第10条（配信者対決型ゲーム）</h3>
          <p>1 有料ユーザーは、相手の有料ユーザー（以下「相手ユーザー」といいます）に対して、ゲームアクセス用のURLを通知することで、配信者対決型ゲームの実施を申し込むことができるものとします。</p>
          <p>2 相手ユーザーは、有料ユーザーが通知したURLによって、自らの操作機器から本システムにアクセスすることにより、ライブ配信サービスの画面において、配信者対決型ゲームに参加することができるものとします。</p>
          <p>3 視聴ユーザーは、配信者対決型ゲーム実施中のライブ配信の様子を視聴することはできますが、配信者対決型ゲームの画面を視聴することはできないものとします。</p>
          <p> </p>
          <h3 id="第11条広告の配信">第11条（広告の配信）</h3>
          <p>1 運営者は、ライブ配信サービスの画面等を通じて、運営者指定の広告配信サービスを利用したうえで、視聴ユーザーに対し、広告の配信を行うことができるものとします。</p>
          <p>2 運営者は、本システムのWebサイト上において、運営者指定の広告配信サービスを利用したうえで、ユーザーに対し、広告の配信を行うことができるものとします。</p>
          <p> </p>
          <h3 id="第12条禁止行為">第12条（禁止行為）</h3>
          <p>ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはならないものとします。</p>
          <p>① 本サービスの目的から逸脱した行為</p>
          <p>② ライブ配信の目的から逸脱した行為</p>
          <p>③ 本システムへの不正アクセスを試みる行為</p>
          <p>④ ゲームの公平性を害する不正なツール、プログラム、システム等を使用した行為</p>
          <p>⑤ 配信プラットフォームの定めたルール、規律、ガイドライン等に反する行為</p>
          <p>⑥ 本人以外の第三者になりすます行為</p>
          <p>⑦ 個人情報その他のプライベート情報を開示する行為</p>
          <p>⑧ 虚偽又は事実と異なる情報を表示する行為</p>
          <p>⑨ 特定の個人、法人、団体を誹謗中傷する行為</p>
          <p>⑩ 政治的又は宗教的な勧誘を伴う行為</p>
          <p>⑪ 法令又は公序良俗に反する行為</p>
          <p>⑫ 本サービスと競合するサービス等の宣伝等を行う行為</p>
          <p>⑬ スパム行為及びこれらに類する行為</p>
          <p>⑭ 本サービス内で不当に情報を操作する行為</p>
          <p>⑮ 不正アクセスや改ざん、ウイルスや有害プログラム等による攻撃行為</p>
          <p>⑯ 自動応答のための装置、ソフトウェア、アルゴリズム等を利用する行為</p>
          <p>⑰ 異性交際を目的として本サービスを利用する行為</p>
          <p>⑱ 本サービスの運営及び運営者の業務を妨害する行為</p>
          <p>⑲ その他運営者が不適切と判断する行為</p>
          <p> </p>
          <h3 id="第13条アカウントの制限停止凍結抹消">第13条（アカウントの制限、停止、凍結、抹消）</h3>
          <p>運営者は、配信ユーザーが以下の各号のいずれかの事由に該当すると判断した場合には、当該配信ユーザーに通知することなく、当該配信ユーザーのアカウントを制限、停止、凍結、抹消を行うことができるものとします。</p>
          <p>① 本規約に違反した場合又はそのおそれがある場合</p>
          <p>② 本システムに登録した情報に虚偽が判明した場合</p>
          <p>③ 過去に本条の措置を受けた事実が判明した場合</p>
          <p>④ 最終ログイン時から運営者の定める期間を経過した場合</p>
          <p>⑤ 支払停止、支払不能、破産、再生、不渡り、差押、租税公課滞納、その他により信用不安が生じた場合</p>
          <p>⑥ 死去が判明した場合</p>
          <p>⑦ その他運営者が判断した場合</p>
          <p> </p>
          <h3 id="第14条本サービスの中断停止終了等">第14条(本サービスの中断、停止、終了等)</h3>
          <p>1 運営者は、以下の各号のいずれかに該当する場合、ユーザーへの予告及び同意なく、本サービスの全部又は一部の提供を中断することができるものとします。なお、以下の事項には、配信プラットフォームや指定決済事業者のシステムに生じたものを含みます。</p>
          <p>① 緊急でコンピュータシステムの点検、保守を行う場合</p>
          <p>② 停電、サーバー故障等が発生した場合</p>
          <p>③ コンピュータ、通信回線等に不良がある場合</p>
          <p>④ 地震、落雷、火災等の不可抗力による場合</p>
          <p>⑤ その他運営者が必要と判断した場合</p>
          <p>2 運営者は、本サービスの事業について、採算性、経済合理性、継続妥当性、その他あらゆる面から判断し、いつでも本サービスを将来に向かって終了することができるものとします。</p>
          <p>3 運営者は、前各項によってユーザーに生じた損害について、いかなる責任も負わないものとします。</p>
          <p> </p>
          <h3 id="第15条個人情報の取扱い">第15条（個人情報の取扱い）</h3>
          <p>1 運営者は、ユーザーの個人情報について、プライバシーポリシーに従って取り扱うものとします。</p>
          <p>2 ユーザーは、本サービスを通じて知り得たユーザー情報その他の個人情報を厳重に管理し、第三者に開示又は漏洩してはならず、本サービスを利用する目的以外で利用してはならないものとします。</p>
          <p> </p>
          <h3 id="第16条権利義務の譲渡">第16条（権利義務の譲渡）</h3>
          <p>ユーザーは、本規約上の地位、権利、義務について、第三者に譲渡してはならないものとします。</p>
          <p> </p>
          <h3 id="第17条情報の保存">第17条（情報の保存）</h3>
          <p>運営者は、本サービスのサーバー上に保存された一切の情報について、運営者所定の期間を超えて保存する義務を負わず、当該期間経過後にいつでも消去できるものとし、消去後の一切の責任を負いません。</p>
          <p> </p>
          <h3 id="第18条非保証等">第18条（非保証等）</h3>
          <p>運営者は、ユーザーに対し、以下のいずれについても保証しないものとします。</p>
          <p>① ユーザー間のやり取りの適切性等</p>
          <p>② ユーザーの人格、態度、振る舞い等の適切性等</p>
          <p>③ ライブ配信の通信品質及び配信内容</p>
          <p>④ 本システムにシステム障害が一切生じないこと</p>
          <p> </p>
          <h3 id="第19条免責">第19条（免責）</h3>
          <p>1 運営者は、ユーザー間又はユーザーと第三者との間で生じたトラブル、苦情、争い、諍い、及びこれらを踏まえた損害賠償請求等に関して、いかなる責任も負わないものとします。</p>
          <p>2 運営者は、本サービス外においてユーザー間で行われたやり取りから生じたあらゆる不具合について、いかなる責任も負わないものとします。</p>
          <p>3 運営者は、本サービスに貼られた外部サイトURLからのリンク先の内容及び当該リンク先で生じたあらゆる不具合について、いかなる責任も負わないものとします。</p>
          <p>4 運営者は、ユーザーの通信回線やコンピュータなどの障害によって生じたあらゆる不具合について、いかなる責任も負わないものとします。</p>
          <p>5 本規約に定めた運営者を免責する規定は、運営者に故意又は重過失が存する場合には適用しないものとします。</p>
          <p> </p>
          <h3 id="第20条反社会的勢力排除">第20条（反社会的勢力排除）</h3>
          <p>1 ユーザーは、自らが暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等社会運動標榜ゴロ又は特殊知能暴力団、その他これらに準ずる者(以下これらを「反社会的勢力」といいます）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。</p>
          <p>① 反社会的勢力が経営を支配していると認められる関係を有すること</p>
          <p>② 反社会的勢力が経営に実質的に関与していると認められる関係を有すること</p>
          <p>③ 自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること</p>
          <p>④ 反社会的勢力に資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</p>
          <p>⑤ 役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること</p>
          <p>2 ユーザーは、自ら又は第三者を利用して次の各号の一つにでも該当する行為を行わないことを確約します。</p>
          <p>① 暴力的な要求行為</p>
          <p>② 法的な責任を超えた不当な要求行為</p>
          <p>③ 取引に関して、脅迫的な言辞又は暴力を用いる行為</p>
          <p>④ 風説を流布し、偽計若しくは威力を用いて相手方の信用を毀損し、又は業務を妨害する行為</p>
          <p> </p>
          <h3 id="第21条損害賠償">第21条（損害賠償）</h3>
          <p>1 ユーザーは、ユーザー又はユーザーの関係者が本規約に違反することによって運営者に損害を与えた場合、運営者に対し、その損害を賠償するものとします。</p>
          <p>2 運営者の故意又は重大な過失による場合を除き、運営者がユーザーに負う損害賠償の金額は、当該ユーザーが直接被った損害金額に限るものとします。</p>
          <p> </p>
          <h3 id="第22条本規約の改訂">第22条（本規約の改訂）</h3>
          <p>運営者は、本規約を改訂する場合、民法第548条の４の定めに従うものとします。</p>
          <p> </p>
          <h3 id="第23条連絡及び通知">第23条（連絡及び通知）</h3>
          <p>1 ユーザーと運営者の間の連絡及び通知は、運営者の定める方法に従うものとします。</p>
          <p>2 ユーザーが運営者に問合せを行う場合、本システム上の問い合わせフォームを使用するものとし、回答方法は運営者が指定するものとします。</p>
          <p> </p>
          <h3 id="第24条本サービスの譲渡">第24条（本サービスの譲渡）</h3>
          <p>運営者は、本サービスの事業を第三者に譲渡（手段を問いません）をした場合には、当該事業譲渡に伴い、本規約に定めた運営者の地位、権利、義務、ユーザーの情報、出品設定の情報、その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、あらかじめ同意するものとします。</p>
          <p> </p>
          <h3 id="第25条その他">第25条（その他）</h3>
          <p>1 運営者は、本システムの機能、デザイン、仕様について、運営者の判断により自由に更新することができるものとします。</p>
          <p>2 本規約のいずれかの条項又はその一部が無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>
          <p>3 本規約に基づく運営者とユーザーの合意は、運営者とユーザー間の事前の合意に優先します。</p>
          <p> </p>
          <h3 id="第26条-準拠法及び管轄合意">第26条 (準拠法及び管轄合意)</h3>
          <p>本規約は、日本法に基づき解釈されるものとします。ユーザーと運営者の間で生じた一切の紛争については、訴額に応じて、運営者の住所地を管轄する地方裁判所又は簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
          <p>以上</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
