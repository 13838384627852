import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-8 col-md-10 mx-auto">
          <h2 id="アマエビゲームズプライバシーポリシー" className="text-center mb-4">アマエビゲームズプライバシーポリシー</h2>
          <p>&nbsp;</p>
          <p> </p>
          <p>
            ライブ配信を行う配信者とその視聴者が、一緒に遊ぶゲームを提供するWebサービス「アマエビゲームズ」（以下「本サービス」といいます）を企画、開発、運営を行う者（以下「運営者」といいます）は、本サービスのユーザー（以下「ユーザー」といいます）の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます）に従い、適切な取扱い及び保護に努めます。
          </p>
          <p> </p>
          <p>1 本プライバシーポリシーにおいて、個人情報及び保有個人データとは、個人情報保護法の定義に従うものとし、その他の定義については、「アマエビゲームズ利用規約」（以下「利用規約」といいます）に従うものとします。</p>
          <p> </p>
          <p>2 運営者は、本サービスを運営するためのWebシステム（以下「本システム」といいます）において、以下の個人情報を取得し、利用目的に従って利用いたします。</p>
          <p>（1） ユーザーからの取得</p>
          <p>① 取得情報：</p>
          <p>· 配信ユーザーからの取得：運営者の指定するプラットフォーム事業者のアカウント、ニックネーム、配信プラットフォームの情報、その他の情報</p>
          <p>· 視聴ユーザーからの取得：配信ユーザー支援を行う際のメールアドレス</p>
          <p>② 利用目的： </p>
          <p>· 本サービスのアカウント付与に関して情報の確認又は管理を行うため</p>
          <p>· 本サービスの提供を行うため</p>
          <p>· 本サービスに関する案内のため</p>
          <p>· 本サービスの月額利用料の精算のため</p>
          <p>· 運営者からの広告、告知、案内等を送るため</p>
          <p>· 利用規約に基づく運営者の行為を実施するため</p>
          <p>· 本サービスの運営に関する事務連絡を行うため</p>
          <p>· 利用規約に違反する行為に対する対応のため</p>
          <p>· 本サービスの利用状況の分析を行うため</p>
          <p>· その他、上記利用目的に付随する目的のため</p>
          <p>（2） 本システムの問い合わせフォームからの取得</p>
          <p>① 取得情報：氏名、メールアドレス、問い合わせ本文</p>
          <p>② 利用目的：問い合わせ等への対応のため</p>
          <p> </p>
          <p>3 クレジットカード番号を取扱う場合、原則として、指定決済事業者のシステムを通じて行うため、運営者は、当該情報の取得、利用、提供等に直接関与せず、かかる情報の保持はいたしません。</p>
          <p> </p>
          <p>4 運営者は、個人情報保護法その他の法令により許容される場合を除き、ユーザーの同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱わず、又は第三者に提供いたしません。但し、次の場合はこの限りではありません。なお、本人から利用目的の通知を求められた場合、第8項の手続きに準じて通知いたします。</p>
          <p>（1） 法令に基づく場合</p>
          <p>（2） 人の生命、身体又は財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき</p>
          <p>（3） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難であるとき</p>
          <p>（4） 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
          <p> </p>
          <p>5 運営者は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。また、運営者は、違法又は不当な行為を助長し、又は誘発するおそれがある方法により個人情報を利用しません。</p>
          <p> </p>
          <p>6 運営者は、保有個人データについて、漏えい、滅失又はき損の防止等のために以下の安全管理措置を講じております。 </p>
          <p>(1) 保有個人データの適正な取扱いの確保のため、関係法令等の遵守や質問及び苦情処理の窓口等について、本プライバシーポリシーを策定しております。</p>
          <p>(2) 保有個人データの取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法や取扱者を定め、運営者所定の手順に従った取扱いを行います。</p>
          <p>(3) 組織的な安全管理措置として、以下を実施しております。</p>
          <p>① 保有個人データの取扱いに関しては、代表自らが責任者となり、個人データを取り扱う業務と範囲を明確化し、随時、個人情報保護法や運営者所定の規律に違反している事実又は兆候を把握するようにしております。</p>
          <p>② 保有個人データの取扱状況について、定期的にチェックを行うとともに、自らの個人データの取扱い状況を過信せず、客観的な目線で確認をしております。</p>
          <p>(4) 人的安全管理措置として、以下を実施しております。</p>
          <p>① 保有個人データの取扱いに関して、意識の強化と知識の向上に努めております。</p>
          <p>② 保有個人データについて、秘密を保持することとしております。</p>
          <p>(5) 物理的安全管理措置として、以下を実施しております。</p>
          <p>① 保有個人データの取扱区域を限定し、第三者の入退室や持出の制限を行っております。</p>
          <p>② 保有個人データを取り扱う機器等の盗難又は紛失等を防止するための措置を講じるとともに、持ち運ぶ場合においても容易に個人データが判明しないよう措置を実施しております。</p>
          <p>(6) 技術的安全管理措置として以下を実施しております。</p>
          <p>① パスワードやアクセス制御等によって、保有個人データを保護しております。</p>
          <p>② ウィルス対策や通信暗号化等によって、保有個人データへの不正アクセス等を防止する仕組みを導入しております。</p>
          <p>(7) 運営者は、意図して保有個人データを外国の第三者に取り扱わせることはありません。</p>
          <p> </p>
          <p>7 運営者は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。</p>
          <p>（1） 運営者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合</p>
          <p>（2） 合併その他の事由による事業の承継に伴って個人情報が提供される場合</p>
          <p>（3） 個人情報保護法の定めに基づき共同利用する場合</p>
          <p> </p>
          <p>
            8
            運営者は、ユーザーから、個人情報保護法の定めに基づき保有個人データ又は第三者提供記録等の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該保有個人データ又は第三者提供記録等が存在しないときにはその旨を通知いたします）。但し、個人情報保護法その他の法令により、運営者が開示の義務を負わない場合は、この限りではありません。
          </p>
          <p> </p>
          <p>
            9
            運営者は、ユーザーから、個人情報保護法の定めに基づき保有個人データの内容の訂正、追加又は削除（以下「訂正等」といいます）を求められた場合には、ユーザーご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、当該保有個人データの内容の訂正等を行い、その旨をユーザーに通知します（訂正等を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします）。但し、個人情報保護法その他の法令により、運営者が訂正等の義務を負わない場合は、この限りではありません。
          </p>
          <p> </p>
          <p>
            10
            運営者は、ユーザーから、個人情報保護法の定めに基づき保有個人データの利用や提供の停止又は消去（以下「利用停止等」といいます）を求められた場合において、そのご請求に理由があることが判明した場合には、ユーザーご本人からのご請求であることを確認の上で、遅滞なく当該保有個人データの利用停止等を行い、その旨をユーザーに通知します。但し、個人情報保護法その他の法令により、運営者が利用停止等の義務を負わない場合は、この限りではありません。
          </p>
          <p> </p>
          <p>11 運営者は、Cookie及びこれに類する技術を利用する方法により、ユーザーから、情報を取得することがあります。</p>
          <p>（1） 入力補助、アクセス解析、広告配信サービスの利用のため、Cookieを通じて情報取得を行うことがあります。</p>
          <p>（2） Cookieはユーザーのコンピュータ内に記録されますが、個人を特定するものは一切含まれません。Cookieを無効化したいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。</p>
          <p>（3） 但し、Cookieを無効化すると、運営者のサービスの一部の機能をご利用いただけなくなる場合があります。</p>
          <p> </p>
          <p>12 ユーザーは、運営者がGoogle Adsenseサービスを利用するにあたり、以下に同意するものとします。</p>
          <p>（1） Google 等の第三者配信事業者が Cookie を使用して、ユーザーが運営者のウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信すること</p>
          <p>（2） Google が広告 Cookie を使用することにより、ユーザーが運営者のウェブサイトや他のウェブサイトにアクセスした際の情報に基づいて、Google やそのパートナーが適切な広告をユーザーに表示できること</p>
          <p>（3） ユーザーは、広告設定でパーソナライズ広告を無効にできること</p>
          <p> </p>
          <p>13 運営者がGoogleアナリティクスサービスを利用するにあたっては、以下のとおりとします。</p>
          <p>（1） ユーザーは、運営者がGoogle アナリティクスサービスを利用するにあたり、以下に同意するものとします。</p>
          <p>運営者がユーザーのデータ取得のために Cookie を使用していること</p>
          <p>（2） (1)に関して、取得される情報、利用目的、第三者への提供等につきましては、以下のGoogleプライバシーポリシーのリンクよりご確認ください。</p>
          <p>· Google プライバシーポリシー</p>
          <p>
            <a href="https://policies.google.com/privacy?hl=ja" target="_blank" class="url">
              https://policies.google.com/privacy?hl=ja
            </a>
          </p>
          <p>· ユーザーが Google パートナーのサイトやアプリを使用する際の Google によるデータ使用</p>
          <p>
            <a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank" class="url">
              https://policies.google.com/technologies/partner-sites?hl=ja
            </a>
          </p>
          <p> </p>
          <p>14 運営者は、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者であり、Amazonのアソシエイトとして、運営者は、適格販売により収入を得ています。</p>
          <p> </p>
          <p>15 開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱い又は運営者の氏名及び住所に関するお問い合わせは、本システムの問い合わせフォーム等を通じて、運営者までご連絡ください。</p>
          <p> </p>
          <p>16 運営者は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを改定することがあります。改定の方法は、利用規約に準じるものとします。</p>
          <p>以上</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
