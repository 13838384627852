import "@aws-amplify/ui-react/styles.css";
import React, { useState, useEffect } from "react";
import { Button, Heading, View, Card } from "@aws-amplify/ui-react";

import { Amplify } from "aws-amplify";
import { signOut, fetchAuthSession, signInWithRedirect, getCurrentUser, updateUserAttributes, deleteUser } from "aws-amplify/auth";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Overview from "./components/Overview";

const domainName = process.env.REACT_APP_DOMAIN_NAME;

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "ap-northeast-1_qD3MmxMCY",
      userPoolClientId: "3llq2udq1qd017rhi9dlrefuq7",
      loginWith: {
        oauth: {
          domain: "pes-adhoc-20240414.auth.ap-northeast-1.amazoncognito.com",
          scopes: ["openid email profile aws.cognito.signin.user.admin"],
          redirectSignIn: ["http://localhost:3000","https://dhganbhcm0k5o.cloudfront.net",domainName],
          redirectSignOut: ["http://localhost:3000","https://dhganbhcm0k5o.cloudfront.net",domainName],
          responseType: "code",
        },
      },
    },
  },
});

function App() {
  //ユーザー情報
  const [user, setUser] = useState();

  //ログインしているかどうかのフラグ
  const [isLogin, setIsLogin] = useState(false);

  //属性（custom:test_Attributes）が登録済かどうかのフラグ
  const [isRegister, setIsRegister] = useState(false);

  //登録する属性の入力文字列
  const [testAttributes, setTestAttributes] = useState("");

  //初期処理　画面リロード時に毎回実行
  useEffect(() => {
    getUser();
  }, []);

  //初期処理　ログインしているかどうかの確認、任意の属性の値を登録済かどうか確認
  const getUser = async () => {
    try {
      console.log("ユーザーがログインしているかどうか確認");
      const currentUser = await getCurrentUser();
      setUser(currentUser);
      setIsLogin(true);
      const session = await fetchAuthSession();
      if (session.tokens.idToken.payload["custom:test_Attributes"]) {
        console.log("属性（custom:test_Attributes）が登録済かどうか確認");
        setIsRegister(true);
      }
    } catch (error) {
      console.log("Not signed in");
    }
  };

  //OIDC Googleサインイン
  async function handleClickSignInWithRedirect() {
    await signInWithRedirect({
      provider: { custom: "Google" },
    });
  }

  //属性の登録
  async function handleClickRegistAttributes() {
    const attributes = await updateUserAttributes({
      userAttributes: {
        "custom:test_Attributes": testAttributes,
      },
    });

    signOut({ global: true }).then(async () => {
      await signInWithRedirect({
        provider: { custom: "Google" },
      });
    });
  }

  //サンプルLambdaの起動
  async function handleClickFetchLambda() {
    const session = await fetchAuthSession();
    console.log(session);
    const idToken = session.tokens.idToken.toString();
    const res = await fetch(`${domainName}/dev/games/init/ec4d16e0-a2fb-4a18-9ecc-03b8c44d7f73`, {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    });
    const resJson = await res.json();
    console.log(resJson);
  }

  
  //サンプルLambdaの起動
  async function handleClickGameStartRPS() {
    try {
      const session = await fetchAuthSession();
      console.log(session);
      const idToken = session.tokens.idToken.toString();
  
      const res = await fetch(`${domainName}/dev/games/rooms/a3c95e6c-b6cf-445c-9c3c-fbf96828ff9e/dealer/rps/start`, {
        method: "POST",
        headers: { Authorization: `Bearer ${idToken}` },
        credentials: "include",
      });
  
      if (!res.ok) {
        throw new Error('Game start failed!');
      }
  
      // リダイレクト先のURLがレスポンスに含まれている場合、以下のようにリダイレクトする
      const resJson = await res.json();
      console.log(resJson);
  
      if (resJson.redirectUrl) {
        window.location.href = domainName + resJson.redirectUrl; // リダイレクト実行
      }
    } catch (error) {
      console.error('Error starting game:', error);
    }
  }

  // ユーザー削除ハンドラー
  async function handleDeleteUser() {
    try {
      await deleteUser();
      console.log("ユーザーが削除されました。");
      signOut({ global: true }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("ユーザー削除中にエラーが発生しました:", error);
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOpen(!isOpen);
  };

  const BootstrapIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 94" className="bootstrap-icon">
      <title>Bootstrap</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
      ></path>
    </svg>
  );

  const Logo = () => (
    <svg className="bi me-2" width="40" height="32" viewBox="0 0 118 94" xmlns="http://www.w3.org/2000/svg">
      <title>Bootstrap</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
      ></path>
    </svg>
  );

  const Card = () => (
    <div className="card">
      <img src="https://via.placeholder.com/150" className="card-img-top" alt="..." />
      <div className="card-body d-flex flex-column">
        <div className="mb-1">
          <h5 className="card-title text-center">じゃんけん</h5>
          <p className="card-text">このテキストはカードの説明文です。ここにコンテンツの概要や重要な情報を記載します。</p>
        </div>
        <div className="mt-auto d-flex justify-content-end">
          <a href="#" className="btn btn-primary" onClick={handleClickGameStartRPS}>
            起動
          </a>
        </div>
      </div>
    </div>
  );

  const AppMain = () =>
    isLogin ? (
      isRegister ? (
        //ログイン済　かつ　属性情報が登録済のとき
        <div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-4 mb-4">
                <Card />
              </div>
              <div className="col-md-4 mb-4">
                <Card />
              </div>
              <div className="col-md-4 mb-4">
                <Card />
              </div>
              {/* 他のカード */}
            </div>
          </div>
        </div>
      ) : (
        //ログイン済　かつ　属性情報が登録されていないとき
        <div>
          <input style={{ marginLeft: "10px", width: "400px" }} id="username" value={testAttributes} placeholder="カスタム属性" onChange={(e) => setTestAttributes(e.target.value)} />
          <Button onClick={handleClickRegistAttributes}>属性登録</Button>
        </div>
      )
    ) : (
      //非ログイン
      <>
        <Overview />
      </>
    );

    // ユーザー情報とログイン状態を管理する状態
// ユーザー情報とログイン状態を管理する状態
const [playerUrl, setPlayerUrl] = useState(''); // 初期値を設定
const [streamerUrl, setStreamerUrl] = useState(''); // 初期値を設定

// サーバーからURLを取得する関数
const fetchUrls = async () => {
  try {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();

    // サーバーからプレイヤー用URLと配信画面用URLを取得
    const response = await fetch(`${domainName}/dev/games/streams/get_urls`, {
      method: "POST",
      headers: { Authorization: `Bearer ${idToken}` },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error('URLの取得に失敗しました。');
    }

    const data = await response.json();
    setPlayerUrl(`${domainName}/dev` + data.player_url); // プレイヤー用URLを状態に設定
    setStreamerUrl(`${domainName}/dev` + data.view_url); // 配信画面用URLを状態に設定

  } catch (error) {
    console.error('URLの取得に失敗しました:', error);
  }
};

// コンポーネントがマウントされた時にURLを取得
useEffect(() => {
  fetchUrls();
}, []);


  return (
    <View className="App">
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" onClick={toggleOffcanvas} aria-controls="sidebarMenu">
            <span className="navbar-toggler-icon"></span>
          </button>

          <a href="/" className="d-flex align-items-center me-lg-auto link-body-emphasis text-decoration-none mx-3">
            <Logo />
            <span className="fs-4">Double header</span>
          </a>

          <div className="ms-auto">
            {isLogin ? (
              //ログイン済のとき
              <div>
                <a
                  className="btn btn-primary"
                  href="#"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    signOut({ global: true }).then(() => {
                      window.location.reload();
                    });
                  }}
                >
                  ログアウト
                </a>
              </div>
            ) : (
              <div>
                <a className="btn btn-primary" href="#" role="button" onClick={handleClickSignInWithRedirect}>
                  ログイン
                </a>
              </div>
            )}
          </div>
        </div>
      </nav>

      <div className={`offcanvas offcanvas-start ${isOpen ? "show" : ""}`} tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="sidebarMenuLabel">
            メニュー
          </h5>
          <button type="button" className="btn-close" onClick={toggleOffcanvas} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          {/* サイドメニューの内容 */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="/privacy-policy">
                プライバシーポリシー
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/terms-of-service">
                利用規約
              </a>
            </li>

            {isLogin ? (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={handleClickFetchLambda}>
                    サンプルlambdaの起動
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={handleDeleteUser}>
                    アカウント削除
                  </a>
                </li>
              </>
            ) : (
              //非ログイン
              <></>
            )}
          </ul>
        </div>
      </div>

      {/* 以下のカードコンポーネントを繰り返し表示 */}
      <Router>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/overview" element={<Overview />} />
          {/* <Route path="*" element={<AppMain />} /> */}
          <Route
            path="*"
            element={
              isLogin ? (
                isRegister ? (
                  //ログイン済　かつ　属性情報が登録済のとき
                  <div>
                    <div className="container mt-5">
                      <div className="row">
                        {/* プレイヤー用の参加用URL表示 */}
                        <div className="col-12 mt-3">
                          <div className="input-group">
                            <input type="text" className="form-control" value={playerUrl} onChange={(e) => setPlayerUrl(e.target.value)} readOnly />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" style={{ width: '150px', whiteSpace: 'nowrap' }} type="button" onClick={() => navigator.clipboard.writeText(playerUrl)}>
                                コピー
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* 配信画面用のURLコピー */}
                        <div className="col-12 mt-3">
                          <div className="input-group">
                            <input type="password" className="form-control" value={streamerUrl} onChange={(e) => setStreamerUrl(e.target.value)} readOnly />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" style={{ width: '150px', whiteSpace: 'nowrap' }} type="button" onClick={() => navigator.clipboard.writeText(streamerUrl)}>
                                配信URLコピー
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Card />
                        </div>
                        <div className="col-md-4 mb-4">
                          <Card />
                        </div>
                        <div className="col-md-4 mb-4">
                          <Card />
                        </div>
                        {/* 他のカード */}
                      </div>
                    </div>
                  </div>
                ) : (
                  //ログイン済　かつ　属性情報が登録されていないとき
                  <div>
                    <div className="container mt-5">
                      <div className="row">
                        <div className="col-md-6 offset-md-3">
                          <h4 className="d-flex justify-content-center">ユーザ登録を更新</h4>
                          <form>
                            <div className="form-group mb-4">
                              {" "}
                              {/* mb-4 を追加して下部の空間を作る */}
                              <label htmlFor="username">ユーザ登録:</label>
                              <input type="text" className="form-control" id="username" value={testAttributes} placeholder="ユーザー名を入力" onChange={(e) => setTestAttributes(e.target.value)} />
                            </div>
                            <div className="d-flex justify-content-center">
                              {" "}
                              {/* ボタンを中央に配置するためのdiv */}
                              <button type="button" className="btn btn-primary" onClick={handleClickRegistAttributes}>
                                登録
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                //非ログイン
                <>
                  <Overview />
                </>
              )
            }
          />
        </Routes>
      </Router>
    </View>
  );
}

export default App;
